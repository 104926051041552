<template>
  <div class="welcome">
    <h1>
      <img id="header" alt="Välkomna" src="../assets/2024/logo.svg">
    </h1>
    <p>
      God jädrans Jul!<br>
      Ännu ett år är över nu.
    </p>
    <p>
      Storma staden och ha kul<br>
      sök högt, låg och brett
    </p>
    <p>
      Men framförallt: Var tillbaka klockan halv sju<br>
      Det blir fett.
    </p>
    <p v-if="gpsfail == true">
      Du måste godkänna GPS i din mobil för att komma vidare!
    </p>
    <button @click="approveGps" v-bind:class="(tryingGps && !gpsfail)?'button-loading':''">Godkänn GPS</button>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  emits: ["start"],
  data() {
    return { 
      tryingGps: false,
    }
  },
  props: {
    gpsfail: Boolean
  },
  methods: {
    approveGps() {
      this.tryingGps = true;
      this.$emit("start");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.welcome {
  /* background: url(../assets/2023start-bkg.jpg) no-repeat center center;
  background-size: cover; */
  height: 100vh;
  background: url(../assets/2024/mark-hogwarts.svg) no-repeat center bottom;

}

#header {
  max-width: 70%;
  height: auto;
  margin: 100px 0 -0.2em;
  z-index: 20;
}
h1 {
  color: var(--color-green);
  font-size: 20vw;
}
p {
  font-size: 17px;
  padding: 0.5em 1em;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}



.balls {
  position: absolute;
  left: 0;
  top: 0;
  width: 50vw;
  height: 60vw;
  overflow: hidden;
  pointer-events: none;
}
.balls > div {
  position: absolute;
  border-radius: 50% 50%;
  animation: 8s ease-in-out infinite ballHover;
}
.ball1 {
  left: -23vw;
  top: 6vw;
  width: 40vw;
  height: 40vw;
  background: var(--color-blue-dark);
}
.ball2 {
  left: 11vw;
  top: 4vw;
  width: 17vw;
  height: 17vw;
  background: var(--color-red);
  transform: translate(0, 3vw);
  animation-delay: 1.3s !important;
}
.ball3 {
  left: 1vw;
  top: 40vw;
  width: 12vw;
  height: 12vw;
  background: var(--color-white);
  transform: translate(0, 3vw);
  animation-delay: 2.7s !important;

}

@keyframes ballHover {
  0%, 100% {
    transform: translate(0, 3vw);
  }
  50% {
    transform: translate(0, -3vw);
  }
}


</style>
