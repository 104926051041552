<template>
  <div class="overlay overlay-info">
    <div class="smaller">
      <h1>
        Så funkar det
      </h1>
      <ol>
        <li>På kartan finns 25 bollar</li>
        <li>Gå till platsen och svara på en fråga för att förvärva numret till er bingobricka.</li>
        <li>Varje boll ger poäng, varje rad ger mer poäng och siktar ni på fyra hörn får ni ännu mer poäng.</li>
        <li class="new">Även i år: Första laget till en boll får en extra poäng för den. Otagna bollar har en guldram på kartan.</li>
        <li>Spelet har högsta säkerhetsklass, det går inte att sitta still och dricka öl och svara på frågorna. Du måste befinna dig på rätt GPS-koordinater för att få tillgång till frågan.</li>
        <li>Samtliga MÅSTE vara tillbaka på kontoret vid angiven tid.</li>
      </ol>
      <button @click="close">Ok</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoOverlay',
  emits: ["close"],
  methods: {
    close() {
      this.$emit("close");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.overlay-info {
  background: rgba(16, 19, 32, 0.9) url(../assets/2024/mark-harry.svg) no-repeat center bottom;
}

#header {
  max-width: 50%;
  height: auto;
  margin: 40px 0 -0.2em;
}
h1 {
  font-size: 46px;
  padding-left: 1em;
  text-align: left;
  padding-top: 1em;
}
ol {
  text-align: left;
  padding-left: 1em;
  font-size: 16px;
  width: 80%;
  margin: 1em auto;
}
li {
  margin: 0.5em 0;
}
.new {
  color: var(--color-gold);
}
p {
  font-size: 20px;
  padding: 0.5em 2em;
}
h3 {
  margin: 40px 0 0;
}
a {
  color: #42b983;
}
.balls {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50vw;
  height: 60vw;
  overflow: hidden;
  pointer-events: none;
}
.balls > div {
  position: absolute;
  border-radius: 50% 50%;
  animation: 8s ease-in-out infinite ballHover;
}
.ball1 {
  right: -10vw;
  bottom: 10vw;
  width: 40vw;
  height: 40vw;
  background: var(--color-blue-dark);
}
.ball2 {
  right: 29vw;
  bottom: 7vw;
  width: 17vw;
  height: 17vw;
  background: var(--color-red);
  transform: translate(0, 3vw);
  animation-delay: 1.3s !important;
}
.ball3 {
  right: 11vw;
  bottom: 1vw;
  width: 12vw;
  height: 12vw;
  background: var(--color-white);
  transform: translate(0, 3vw);
  animation-delay: 2.7s !important;

}

@keyframes ballHover {
  0%, 100% {
    transform: translate(0, 3vw);
  }
  50% {
    transform: translate(0, -3vw);
  }
}

</style>
