<template>
  <Welcome @start="approveGps" v-if="state=='welcome'" :gpsfail="gpsfail" />
  <Game v-if="state=='game'" :team="team" :position="position" />
  <CodeFail v-if="state=='codefail'" />
</template>

<script>
import Welcome from './components/Welcome.vue'
import Game from './components/Game.vue'
import CodeFail from './components/CodeFail.vue'

export default {
  name: 'App',
  mounted() {
    console.log('onMounted');
    const urlParams = new URLSearchParams(window.location.search);
    this.team = String(urlParams.get('team'));
    if (this.team.length != 5 && this.team.length != 6) {
      this.state = 'codefail';
    } else {
      this.state = "welcome";
    }

  },
  data() {
    return { 
      state: 'loading',
      watchID: null,
      position: [11.969954880747407, 57.70367269300378],
      gpsfail: false,
      team: ''
    }
  },
  components: {
    Welcome,
    Game,
    CodeFail
  },
  methods: {
    approveGps() {
      console.log('get location!');
      this.watchID = navigator.geolocation.watchPosition(this.updateGps, this.failedGps, { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 });
    },
    updateGps(position) {
      this.position = [ position.coords.longitude, position.coords.latitude ];
      console.log('Update gps', this.position);

      this.state = 'game';
    },
    failedGps() {
      this.gpsfail = true;
    }
  }
}
</script>

<style lang="scss">

:root {
  --color-background: #101320;
  --color-text: #F1F1F1;
  --color-button-text: #A2FFFF;
  --color-tabs: #004851;
  --color-tabs-active: #ff6b00;
  --color-bingocard: #960008;
  --color-bingocard-ball: var(--color-tabs-active);
  --color-textfield-background: #25D9E8;

  
  --color-white: #f7f2e7;
  --color-black: #080E15;
  --color-grey: #949393;
  --color-grey-dark: #4F4F4E;
  --color-yellow: #ffe445;
  --color-yellow-dark: #A18900;
  --color-yellow-light: #F0D98E;
  --color-red: #ff6b00;
  --color-gold: gold;
  --color-blue: #1931e0;
  --color-blue-dark: #01206d;

  --color-green: var(--color-blue); /* #1A8D7C; */
  --color-green-light: var(--color-blue-dark); /* #add1cc; */


  --color-button: var(--color-yellow);
  // --color-button-text: var(--color-blue);
  --color-title: var(--color-red);


  --font-text: "Montserrat", Helvetica, Arial, sans-serif;
  --font-header: "IM Fell English", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English:ital@0;1&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap');
//@import url('https://use.typekit.net/got4bfr.css');

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  overflow: hidden;
}
#app {
  font-family: var(--font-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: var(--color-background) no-repeat center center;
  background-size: cover;
  color: var(--color-text);
}

button {
  width: calc(100% - 2em);
  margin: 1em 1em;
  padding: 0.8em 1.6em;
  font-size: 20px;
  font-weight: 500;

  // border: solid 1px transparent;
  border: solid 1px var(--color-button-text);
  border-radius: 8px;
  background: var(--color-background);
  color: var(--color-button-text);
  // background-clip: padding-box;
  // position: relative;

  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 0; right: 0; bottom: 0; left: 0;
  //   z-index: -1;
  //   margin: -1px; /* !importanté */
  //   border-radius: inherit; /* !importanté */
  //   background: linear-gradient(to right, red, orange);
  // }

  &:disabled {
    background: var(--color-grey-dark);
  }
}
h1, h2, h3, h4 {
  font-family: var(--font-header);
  font-weight: normal;
  margin: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(16, 19, 32, 0.90);
}

.top-graphics {
  background: url(assets/topleft.png) no-repeat top 1em left 1em, url(assets/topright.png) no-repeat top 1em right 1em;
  background-size: 25vw, 25vw;
}
.smaller {
  background-size: 20vw, 20vw;
}


input {
  &[type=text] {
    font-size: 16px;
    border: 1px solid #000;
    border-radius: 0.25em;
    width: 100%;
    padding: 1em 1em;
  }

  &[type=file] {
    width: 100%;
    display: flex;
    font-size: 0;
    
    &::-webkit-file-upload-button,
    &::file-selector-button {
      visibility: hidden;
    }
    &::before {
      content: 'Ta en bild';
      display: inline-block;
      margin: 1em 0;
      padding: 1em 2em;
      background: #ce1417;
      color: white;
      font-size: 16px;
      border: 0;
      border-radius: 30px;
      outline: none;
      white-space: nowrap;
      user-select: none;
      cursor: pointer;
      text-align: center;
    }    
  }
}




.button-loading {
  text-indent: -9999em;
  position: relative;

  &:after {
    content: "";
    color: #ffffff;
    font-size: 3.5px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    animation: loading-spinner 0.8s infinite linear;
    transform: translate3d(-0.5em, -0.5em, 0);
  }
}
@keyframes loading-spinner {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

footer {
  position: fixed;
  bottom: 1em;
  left: 2em;
  right: 2em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: var(--color-yellow);

  &:before, &:after {
    content: '';
    position: absolute;
    width: 4em;
    top: 0.5em;
    height: 0.5em;
    background: var(--color-green);
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}


.divider {
  margin: 1em 0;
}
.divider-half {
  width: 25%;
}
.divider-full {
  width: 50%;
}

</style>
